.hero {
    align-items: center;
    background-color: var(--background-light);
}

.hero p {
    text-align: left;
    font-size: x-large;
    display: inline-block;
    color: var(--text-dark);
    margin-bottom: 2rem;
}

.hero-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}

.hero-image video {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .hero {
        text-align: center;
    }

    .hero-image {
        width: 100%;
    }

    .hero-image video {
        width: 100%;
    }
}

.hero {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    min-height: 100vh;
}

.hero-bottom {
    align-items: right !important;
    color: var(--primary-blue);
    z-index: 1;
    padding-top: 10px !important;
    min-width: 100% !important;
}

.hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: var(--primary-blue);
    text-align: left;
}

@media (max-width: 768px) {
    .hero {
        padding: 2rem 1rem;
    }

    .hero .primary-button {
        width: 100%;
        margin-top: 1rem;
    }
}

.hero {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    align-items: left;
    justify-content: flex-start;
}

.hero h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: var(--primary-blue);
}

.hero .primary-button {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
}

@media (max-width: 768px) {
    .hero h1 {
        font-size: 2rem;
        text-align: center;
        margin-bottom: 0.75rem;
    }

    .hero {
        min-height: 60vh;
        justify-content: center;
        padding: 1rem 0;
        align-items: center;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .hero + section {
        margin-top: 0;
        padding-top: 0;
    }

    .hero p {
        font-size: 1rem;
        text-align: center;
    }
}

.hero {
    position: relative;
    overflow: hidden;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem 2rem;
}

@media (max-width: 768px) {
    .hero .primary-button,
    .hero .secondary-button {
        width: 100%;
        max-width: 250px;
    }
}

.hero-content-container {
    display: inline-flex;
    width: 100%;
    max-width: 1280px;
    margin-bottom: 3em;
    gap: 3em;
}

.hero-content-left {
    text-align: left;
}

.hero-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    mix-blend-mode: normal;
    mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
    object-fit: contain;
}

@media (max-width: 900px) {
    .hero-content-container {
        display: inline-block;
    }

    .hero-content-left {
        text-align: center;
    }

    .hero-video {
        margin-top: 3em;
        margin-bottom: 3em;
    }
}
