.flag-language-selector {
    position: relative;
    display: inline-block;
    z-index: 1000;
}

.selected-flag {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.selected-flag:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.flag-options {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 40px;
}

.flag-options button {
    background: none;
    border: none;
    font-size: 24px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.flag-options button:hover {
    background-color: #f5f5f5;
}

@media (max-width: 768px) {
    .flag-language-selector {
        display: none;
    }

    .mobile-menu .flag-language-selector {
        display: block;
        margin-top: 10px;
    }

    .mobile-menu .flag-options {
        position: static;
        border: none;
        box-shadow: none;
        background-color: transparent;
    }
}

.desktop-language-selector {
    margin-left: 1rem;
}

.flag-language-selector {
    position: relative;
    display: inline-block;
}

.selected-flag {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.selected-flag:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.flag-options {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 40px;
    z-index: 1000;
}

.flag-options button {
    background: none;
    border: none;
    font-size: 24px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.flag-options button:hover {
    background-color: #f5f5f5;
}

.mobile-language-selector {
    display: none;
}

@media (max-width: 1300px) {
    .desktop-language-selector {
        display: none;
    }

    .mobile-language-selector {
        display: block;
        margin-top: 1rem;
    }

    .mobile-language-selector .flag-options {
        position: static;
        background-color: transparent;
        border: none;
        box-shadow: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .mobile-language-selector .flag-options button {
        padding: 5px;
    }
}
