/* Contact Section */
.contact-section {
    padding: 2rem 2rem;
    background-color: #f0f4f8;
}

.contact-section h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: var(--primary-blue);
}

.contact-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.contact-form {
    flex: 1;
    max-width: 500px;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.contact-form textarea {
    height: 150px;
    resize: vertical;
}

.submit-button {
    background-color: var(--primary-blue);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0043a4;
}

.contact-info {
    flex: 1;
    max-width: 400px;
    padding-left: 2rem;
}

.contact-info h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--primary-blue);
}

.contact-details p {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.contact-details svg {
    margin-right: 0.5rem;
}

@media (max-width: 768px) {
    .contact-content {
        flex-direction: column;
    }

    .contact-form,
    .contact-info {
        max-width: 100%;
    }

    .contact-info {
        padding-left: 0;
        margin-top: 2rem;
    }
}

.contact-section {
    padding: 2rem 2rem;
    background-color: #f8f9fa;
}

.contact-container {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    padding-top: 3rem;
    margin-bottom: 2rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    max-width: 600px;
}

.contact-section h2 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    color: var(--primary-blue);
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    position: relative;
    margin-bottom: 2rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.75rem 0;
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid #ccc;
    background-color: transparent;
    transition: border-color 0.3s;
}

.form-group label {
    position: absolute;
    top: 0.75rem;
    left: 0;
    font-size: 1rem;
    color: #777;
    transition: all 0.3s;
    pointer-events: none;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-bottom-color: var(--primary-blue);
}

.form-group input:focus + label,
.form-group textarea:focus + label,
.form-group input:not(:placeholder-shown) + label,
.form-group textarea:not(:placeholder-shown) + label {
    top: -1.25rem;
    font-size: 0.8rem;
    color: var(--primary-blue);
}

.form-group textarea {
    height: 100px;
    resize: vertical;
}

.submit-button {
    align-self: flex-start;
    background-color: var(--primary-blue);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
}

.submit-button svg {
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
}

.submit-button:hover {
    background-color: #0043a4;
    transform: translateY(-2px);
}

.submit-button:hover svg {
    transform: translateX(5px);
}

@media (max-width: 768px) {
    .contact-section {
        padding: 4rem 1rem;
    }
}

.contact-section {
    padding: 2rem 2rem;
    background-color: #f8f9fa;
}

.contact-section h2 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    color: var(--primary-blue);
}

.contact-intro {
    text-align: center;
    margin-bottom: 3rem;
    color: var(--text-light);
}

.contact-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.contact-form {
    flex: 1;
    max-width: 500px;
    margin-right: 2rem;
}

.form-group {
    position: relative;
    margin-bottom: 2rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.75rem 0;
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid #ccc;
    background-color: transparent;
    transition: border-color 0.3s;
}

.form-group label {
    position: absolute;
    top: 0.75rem;
    left: 0;
    font-size: 1rem;
    color: #777;
    transition: all 0.3s;
    pointer-events: none;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-bottom-color: var(--primary-blue);
}

.form-group input:focus + label,
.form-group textarea:focus + label,
.form-group input:not(:placeholder-shown) + label,
.form-group textarea:not(:placeholder-shown) + label {
    top: -1.25rem;
    font-size: 0.8rem;
    color: var(--primary-blue);
}

.form-group textarea {
    height: 100px;
    resize: vertical;
}

.submit-button {
    background-color: var(--primary-blue);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
}

.submit-button svg {
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
}

.submit-button:hover {
    background-color: #0043a4;
    transform: translateY(-2px);
}

.submit-button:hover svg {
    transform: translateX(5px);
}

.contact-info {
    flex: 1;
    max-width: 400px;
    padding-left: 2rem;
    border-left: 1px solid #e0e0e0;
}

.contact-info h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--primary-blue);
}

.contact-info p {
    margin-bottom: 1.5rem;
    color: var(--text-light);
}

.contact-info ul {
    list-style-type: none;
    padding: 0;
}

.contact-info li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: var(--text-light);
}

.contact-info li svg {
    margin-right: 0.5rem;
    color: var(--primary-blue);
}

.contact-info a {
    color: var(--text-light);
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-info a:hover {
    color: var(--primary-blue);
}

@media (max-width: 768px) {
    .contact-section {
        padding: 4rem 1rem;
    }

    .contact-container {
        flex-direction: column;
    }

    .contact-form {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 3rem;
    }

    .contact-info {
        max-width: 100%;
        padding-left: 0;
        border-left: none;
        border-top: 1px solid #e0e0e0;
        padding-top: 2rem;
    }
}
