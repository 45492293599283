.faq-content {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
}

.faq-display {
    background-color: white;
    border-radius: 10px;
    padding: 0.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.faq-question {
    font-size: 1.25rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 1rem;
}

.faq-response {
    font-weight: bold;
    color: #4361ee;
}

.faq-list-item {
    color: #4361ee;
    font-size: medium;
}

@media (max-width: 768px) {
    .faq-content {
        padding: 2rem 1.5rem;
    }

    .faq-display {
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .faq-question {
        font-size: 1rem;
        margin-bottom: 0.75rem;
    }

    .faq-response {
        font-size: 0.9rem;
    }

    .faq-list-item {
        font-size: 0.85rem;
        margin-bottom: 0.5rem;
    }
}

.faq-response,
.faq-list-item {
    padding-left: 3rem; /* Increased horizontal padding */
    padding-right: 3rem; /* Increased horizontal padding */
    margin-bottom: 1rem;
    color: #4361ee;
}

/* For mobile screens, reduce the padding */
@media (max-width: 768px) {
    .faq-response,
    .faq-list-item {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.faq-question-container {
    width: 100%;
    transition: background-color 0.2s ease;
}

.faq-question-container:hover {
    background-color: rgba(67, 97, 238, 0.05);
}

.faq-question {
    margin: 0;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left; /* Ensure text is always left-aligned */
}

.faq-question span {
    flex: 1;
    text-align: left; /* Ensure text is always left-aligned */
}

.faq-response,
.faq-list-item {
    padding-left: 3rem;
    padding-right: 3rem;
    margin-bottom: 1rem;
    color: #4361ee;
    text-align: left; /* Ensure text is always left-aligned */
}

@media (max-width: 768px) {
    .faq-response,
    .faq-list-item {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .faq-question {
        padding: 0.75rem 1rem;
    }

    /* Ensure text stays left-aligned on mobile */
    .faq-question,
    .faq-question span,
    .faq-response,
    .faq-list-item {
        text-align: left;
    }
}
