.team-section {
    padding: 4rem 2rem;
    background-color: #f8f9fa;
}

.team-section h2 {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 2.5rem;
    color: var(--primary-blue);
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, min(250px));
    gap: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: center;
}

.team-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.team-member-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
}

/* Remove border radius on default image because we use the Validia logo */
.team-member-image.default {
    border-radius: 0;
}

.team-card h3 {
    margin: 0;
    font-size: 1.2rem;
    color: var(--primary-blue);
}

.team-card p {
    margin: 0.5rem 0 1rem;
    font-size: 1rem;
    color: var(--text-light);
}

@media (max-width: 768px) {
    .team-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}
