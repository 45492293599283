/* Footer */
.footer {
    background-color: var(--primary-blue);
    color: white;
    padding: 3rem 2rem 1rem;
    padding-bottom: 2rem;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-logo {
    font-size: 1.5rem;
    font-weight: bold;
}

.footer-links {
    max-width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-links a {
    color: white;
    text-decoration: none;
    margin: 0 1rem;
    transition: opacity 0.3s ease;
}

.footer-links a:hover {
    opacity: 0.8;
}

.footer-bottom {
    text-align: center;
    padding-top: 1rem;
    font-size: 0.9rem;
}

.social-links {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.social-icon {
    font-size: 1.5rem;
    color: var(--primary-blue);
    transition: color 0.3s ease;
}

.social-icon:hover {
    color: #0043a4;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        text-align: center;
    }

    .footer-links,
    .social-links {
        margin-top: 1rem;
    }

    .footer-links a,
    .social-links a {
        margin: 0 0.5rem;
    }
}

@media (max-width: 768px) {
    .footer-bottom {
        font-size: 0.8rem;
        padding: 1rem;
    }

    .footer-content {
        flex-direction: column;
        text-align: center;
    }

    .footer-links,
    .social-links {
        margin-top: 1rem;
    }

    .footer-links a {
        margin: 0 0.4rem;
    }

    .social-links a {
        margin: 0 0.5rem;
        font-size: 0.8rem;
    }
}

.stats-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-left: 1em;
    padding-right: 1em
}

.stats-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.stats-logo {
    width: 180px;
    height: 80px;
    object-fit: contain;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.stats-logo:hover {
    opacity: 1;
}

.stats-logo-text {
    color: #4cc9f0;
    font-size: 0.9rem;
    text-align: center;
    margin: 0;
}

@media (max-width: 768px) {
    .stats-logos {
        flex-direction: column;
        height: auto;
        gap: 2rem;
    }

    .stats-logo {
        height: 50px;
    }

    .stats-logo-text {
        font-size: 0.8rem;
    }
}
