.testimonial-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.testimonial-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.testimonial-card {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    position: relative;
    transition: transform 0.3s ease;
}

.testimonial-card:hover {
    transform: translateY(-10px);
}

.testimonial-text {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    color: #333;
}

.testimonial-author {
    font-weight: bold;
    color: var(--primary-blue);
}

@media (max-width: 768px) {
    .testimonial-container {
        flex-direction: column;
        align-items: center;
    }

    .testimonial-card {
        width: 100%;
    }
}

.testimonial-showcase {
    background-color: #f8f9fa;
    padding: 6rem 0;
    position: relative;
}

.testimonial-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.testimonial-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.testimonial-showcase {
    background-color: #f8f9fa;
    padding: 6rem 0;
    position: relative;
}

.testimonial-showcase::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    background: linear-gradient(90deg, #4cc9f0, #4361ee, #3a0ca3);
}

.testimonial-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.testimonial-showcase h2 {
    font-size: 2.5rem;
    color: var(--primary-blue);
    margin-bottom: 3rem;
}

.testimonial-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.selector-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.selector-item svg {
    font-size: 2rem;
    padding: 1rem;
    border-radius: 50%;
    background-color: #e0e0e0;
    color: #777;
    transition: all 0.3s ease;
}

.selector-item span {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #777;
    text-transform: capitalize;
}

.selector-item.active svg {
    background-color: var(--primary-blue);
    color: white;
    transform: scale(1.1);
}

.selector-item.active span {
    color: var(--primary-blue);
    font-weight: bold;
}

.testimonial-display {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.testimonial-text {
    font-size: 1.25rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 1rem;
}

.testimonial-author {
    font-weight: bold;
    color: #4361ee;
}

@media (max-width: 768px) {
    .testimonial-showcase {
        padding: 4rem 1rem;
    }

    .testimonial-selector {
        flex-direction: column;
        align-items: center;
    }

    .selector-item {
        margin: 1rem 0;
    }

    .testimonial-text {
        font-size: 1rem;
    }
}

.testimonial-selector {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 3rem;
}

.selector-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 120px;
}

.selector-item svg {
    font-size: 2rem;
    padding: 1rem;
    border-radius: 50%;
    background-color: #e0e0e0;
    color: #777;
    transition: all 0.3s ease;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selector-item span {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #777;
    text-transform: capitalize;
    text-align: center;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selector-item.active svg {
    background-color: var(--primary-blue);
    color: white;
    transform: scale(1.1);
}

.selector-item.active span {
    color: var(--primary-blue);
    font-weight: bold;
}

.testimonial-selector {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 3rem;
}

.selector-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 120px;
}

.selector-item svg {
    font-size: 2rem;
    padding: 1rem;
    border-radius: 50%;
    background-color: #e0e0e0;
    color: #777;
    transition: all 0.3s ease;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.selector-item span {
    font-size: 0.9rem;
    color: #777;
    text-transform: capitalize;
    text-align: center;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selector-item.active svg {
    background-color: var(--primary-blue);
    color: white;
    transform: scale(1.1);
}

.selector-item.active span {
    color: var(--primary-blue);
    font-weight: bold;
}

@media (max-width: 768px) {
    .testimonial-selector {
        flex-direction: column;
        align-items: center;
    }

    .selector-item {
        width: 100%;
        max-width: 200px;
        margin-bottom: 1.5rem;
    }

    .selector-item svg {
        margin-bottom: 0.5rem;
    }

    .selector-item span {
        height: auto;
    }
}
