/* Accessibility styling for if users prefer reduced motion (ex: some people with vestibular motion disorders) */
@media (prefers-reduced-motion) {
    /* Remove animation from the enterprise integrations */
    .integration-item-primary-set, .integration-item-secondary-set {
        animation: none;
    }

    .integration-item-primary-set {
        overflow-x: scroll;
    }

    .integration-item-primary-set::before {
        content: "Your browser settings indicate that you prefer reduced motion, so we've turned off auto-scroll here. You can manually scroll horizontally through our enterprise integrations.";
        width: 200px;
        min-width: 200px;
        max-width: 100vw;
        text-align: left;
    }

    .integration-item-secondary-set {
        display: none;
    }
}
