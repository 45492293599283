/* NOTE: before adding CSS to this file, check if it can be added to a component CSS file in src/components, instead */

@import "./fonts.css";

:root {
    --primary-blue: #022f6b;
    --text-dark: #172b4d;
    --text-light: #42526e;
    --background-light: #f4f5f7;
}

body {
    margin: 0;
    font-family: "main", sans-serif;
    color: var(--text-dark);
    background-color: white;
}

html,
body {
    overflow-x: hidden;
    width: 100%;
    margin: 0;
    padding: 0;
}

.App {
    text-align: center;
    overflow-x: hidden;
    position: relative;
    width: 100%;
}

nav ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

nav ul li {
    margin: 0 1rem;
}

nav ul li a {
    text-decoration: none;
    color: var(--text-light);
}

.cta-buttons button {
    margin-left: 1rem;
}

.primary-button {
    background-color: var(--primary-blue) !important;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.secondary-button {
    background-color: white;
    color: var(--primary-blue) !important;
    border: 1px solid var(--primary-blue) !important;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.unified-platform {
    padding: 4rem 2rem;
    background-color: var(--background-light);
}

.unified-platform h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: var(--primary-blue);
}

.integrations-section {
    padding: 6rem 3rem;
    background-color: #f0f4f8;
}

.integrations-section h2 {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 3rem;
    color: var(--primary-blue);
}

.integrations-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.25rem;
    max-width: 1500px;
    margin: 0 auto;
}

.integration-item {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 6px 9px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 2.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.integration-item:hover {
    transform: translateY(-7px);
    box-shadow: 0 9px 18px rgba(0, 0, 0, 0.15);
}

.integration-item.highlight {
    grid-column: span 2;
    background-color: var(--primary-blue);
    color: white;
}

.integration-item.highlight h3 {
    font-size: 2.25rem;
    margin: 0;
}

.social-links a {
    color: white;
    font-size: 1.5rem;
    margin-left: 1rem;
    transition: opacity 0.3s ease;
}

.social-links a:hover {
    opacity: 0.8;
}

@keyframes scrollStats {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.about-us-display {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.about-us-paragraph {
    font-size: 1.25rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 1rem;
}

.menu-toggle {
    display: none;
    font-size: 1.5rem;
    background: none;
    border: none;
    color: var(--primary-blue);
    cursor: pointer;
}

@media (max-width: 1300px) {
    .menu-toggle {
        display: block;
        position: absolute;
        right: 1rem;
    }

    nav {
        width: 100%;
        display: none;
    }

    nav.show {
        display: block;
    }

    nav ul {
        flex-direction: column;
        width: 100%;
    }

    nav ul li {
        margin: 0.5rem 0;
    }

    .cta-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
    }
}

.mobile-cta {
    display: none;
}

@media (max-width: 1300px) {
    nav {
        width: 100%;
        display: none;
    }

    nav.show {
        display: block;
    }

    nav ul {
        flex-direction: column;
        width: 100%;
    }

    nav ul li {
        margin: 0.5rem 0;
    }

    .mobile-cta {
        display: block;
        margin-top: 1rem;
    }

    .mobile-cta button {
        width: 100%;
        max-width: 300px;
    }

    .desktop-cta {
        display: none;
    }
}

@keyframes pulse {
    0%,
    100% {
        transform: scale(1);
        opacity: 0.5;
    }

    50% {
        transform: scale(1.05);
        opacity: 1;
    }
}

.feature-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.feature-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 1rem;
    transition: all 0.3s ease;
}

.feature-item:hover {
    background-color: #e9ecef;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feature-icon {
    color: #4361ee;
    font-size: 1.2rem;
    margin-right: 1rem;
    flex-shrink: 0;
}

.feature-text {
    flex: 1;
}

@media (max-width: 768px) {
    .feature-item {
        padding: 0.75rem;
    }

    .feature-icon {
        font-size: 1rem;
        margin-right: 0.75rem;
    }

    .feature-text {
        font-size: 0.9rem;
    }
}

.blog-section {
    padding: 4rem 2rem;
    background-color: #f8f9fa;
}

.blog-section h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: var(--primary-blue);
}

.blog-posts-container {
    overflow-x: auto;
    padding-bottom: 1rem;
}

.blog-posts {
    display: flex;
    gap: 2rem;
    padding: 1rem;
}

@media (max-width: 768px) {
    .blog-section {
        padding: 3rem 1rem;
    }

    .blog-posts-container {
        margin: 0 -1rem;
    }
}

.blog-section {
    padding: 4rem 2rem;
    background-color: #f8f9fa;
}

.blog-section h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: var(--primary-blue);
}

.blog-posts-container {
    overflow-x: auto;
    padding-bottom: 1rem;
}

.blog-posts {
    display: flex;
    gap: 2rem;
    padding: 1rem;
}

.blog-post-card {
    flex: 0 0 auto;
    width: 300px;
    border: 1px solid #e0e0e0;
    padding: 1.5rem;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.blog-post-card:hover {
    transform: translateY(-5px);
}

.blog-post-card h3 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    color: var(--primary-blue);
}

.blog-post-card p {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 1rem;
}

.blog-post-card a {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: var(--primary-blue);
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.blog-post-card a:hover {
    background-color: #0043a4;
}

@media (max-width: 768px) {
    .blog-section {
        padding: 3rem 1rem;
    }

    .blog-posts-container {
        margin: 0 -1rem;
    }

    .blog-post-card {
        width: 260px;
    }
}

.schedule-demo-page {
    display: none;
}

@media (max-width: 768px) {
    .integration-logos img {
        height: 30px;
        margin: 0.3rem;
    }
}

@media (max-width: 768px) {
    html,
    body {
        overflow-x: hidden;
        width: 100%;
        position: relative;
    }
}

.award-banner {
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(8px);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
    transition: all 0.3s ease;
}

.award-banner img {
    height: 16px;
    width: auto;
    display: block;
}

.award-banner:hover {
    background-color: rgba(255, 255, 255, 0.95);
    transform: translateY(-1px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.award-banner .divider {
    height: 16px;
    width: 1px;
    background-color: #e2e8f0;
}

@media (max-width: 768px) {
    .award-banner {
        padding: 0.4rem 0.8rem;
        gap: 0.75rem;
    }
}
