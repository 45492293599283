.thank-you-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
    padding: 2rem;
    background: linear-gradient(135deg, #f6f9fc 0%, #e9f2f9 100%);
}

.thank-you-container h1 {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.thank-you-container p {
    font-size: 1.2rem;
    color: #34495e;
    margin-bottom: 2rem;
}

.home-link {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: #3498db;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.home-link:hover {
    background-color: #2980b9;
}
