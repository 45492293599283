h2 {
    font-size: 2.5rem;
    margin-bottom: 0.5em;
    color: var(--primary-blue);
}

.blog-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: none;
}

.blog-container.loaded {
    display: block;
    animation: fadeIn 0.8s ease-in forwards;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.posts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
    margin-top: 40px;
    padding-bottom: 10%;
}

.post-card {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.2s, box-shadow 0.2s;
    border: 1px solid #eee;
}

.post-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.post-card .post-content {
    padding: 20px;
}

.post-card .post-meta {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 10px;
}

.post-card .post-title {
    font-size: 1.4rem;
    margin-bottom: 10px;
    font-weight: 600;
}

.post-card .post-excerpt {
    color: #666;
    line-height: 1.5;
}

.post-card .read-more {
    display: inline-block;
    margin-top: 15px;
    color: #000;
    text-decoration: none;
    font-weight: 500;
}

.post-card .read-more:hover {
    text-decoration: underline;
}


@media (max-width: 768px) {
    .posts-grid {
        grid-template-columns: 1fr;
    }
}
